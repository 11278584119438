<template>
      
  <div class="Route">
    <!-- 网站地图 -->
    <div class="Router">
      <router-link tag="div" :to="item.menu_address" class="header" v-for="(item, key) in arr" :key="key">
        <span class="curse">
        {{ item.menu_name }}

        </span>
        <br />
        <img src="../assets/link/routermapIcon.png" alt="" />
        <router-link tag="div" :to="items.menu_address" class="listed" v-for="(items, keys) in item.child" :key="keys">
        
                <span class="curse">
        {{ items.menu_name }}

        </span> 
        </router-link>
      </router-link>
    </div>
       <div class="quxiao curse" @click="$router.go(-1)">

    </div>
  </div>
</template>

<script>
import { fetch } from "../api/https";
export default {
  data() {
    return {
      arr: [],
    };
  },
  created() {},
  mounted() {
    this.axio();
  },
  methods: {
    axio() {
      fetch("/getmenu")
        .then((res) => {
          this.arr = res.data;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped>
.Route {
  position: relative;
  /* width: 100%; */
  height: 100%;
  background: url("../assets/link/nav.png");
  background-size: 100% 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  z-index: 99999999999;
  /* position: fixed;
  top: 0;
  left: 0; */
  overflow-y: scroll;
}
.Router {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  overflow-y: scroll;
}
.header {
  color: white;
  font-size: 22px;
  text-align: center;
  font-size: 22px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #FFFFFF;
min-width: 88px;
}
.header img {
  height: 30px;
  width: 29px;
  margin-top: 18px;
  margin-bottom: 12px;
}
.curse{
  cursor: pointer;
}
.listed {
  margin-top: 28px;
  font-size: 18px;
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.8;
}
.quxiao {
    position: absolute;
    width: 60px;
    height: 60px;
  background: url('../assets/close.png');
  background-size: 60px 60px;
  right: 60px;
  top: 60px;
}
@media screen and (max-width: 1440px){
  .search-title{
    width: 1266px;
    margin: 0 auto;
  }
  .results-num{
    margin: 0 auto;
    width: 1266px;
  }
  .result{
    margin: 0 auto;
    width: 1266px;
    
  }
}
</style>
